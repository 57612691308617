import React, { createContext, ReactNode, useContext } from 'react'

interface GiveawayTypographyProvider {
  children: ReactNode
  theatricalSlug: string
}

interface Value {
  theatricalSlug: string
}

const GiveawayTypographyContext = createContext<Value>({ theatricalSlug: '' })

export const useGiveawayTypographyContext = () => useContext(GiveawayTypographyContext)

export const GiveawayTypographyProvider: React.FC<GiveawayTypographyProvider> = ({ children, theatricalSlug }) => {
  return (
    <GiveawayTypographyContext.Provider
      value={{
        theatricalSlug: theatricalSlug === 'rule-breakers-tech' ? 'rule-breakers' : theatricalSlug,
      }}
    >
      {children}
    </GiveawayTypographyContext.Provider>
  )
}
