import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { EyebrowMD, FontWeight, ParagraphLG, Text, TextProps, TitleSM } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { NamedColor } from '@/constants/types'
import { useGiveawayTypographyContext } from '@/views/PreSales/PreSalesGiveawayView/components/GiveawayTypographyProvider'

interface TextCompontent {
  color?: NamedColor
  weight?: FontWeight
  onClick?: () => void
  children: ReactNode
  className?: string
}

export type StyleMap = Partial<Record<string, string>>

export interface TextClasses {
  display: StyleMap
  titleXXL: StyleMap
  titleXL: StyleMap
  titleLG: StyleMap
  titleMD: StyleMap
  eyebrowXL: StyleMap
  eyebrowLG: StyleMap
  eyebrowMD: StyleMap
  eyebrowSM: StyleMap
  btnMD: StyleMap
  btnSM: StyleMap
  btnXS: StyleMap
  ticketValue: StyleMap
  stepValueXL: StyleMap
  stepValueLG: StyleMap
  stepValueMD: StyleMap
  tabText: StyleMap
}

export const textClasses: TextClasses = {
  // DISPLAY
  display: {
    [slugs.homestead]: '!font-dharma text-[3.5rem] !font-[800] leading-[56px] tracking-[1.75px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[3.5rem] !font-[700] leading-[56px] tracking-[1.75px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[3.5rem] !font-[400] leading-[56px] tracking-[1px] !uppercase',
    [slugs.kingOfKings]: 'text-[3.5rem] !font-bold leading-[67.2px]',
    [slugs.theLastRodeo]: 'text-[3.5rem] !font-bold leading-[67.2px]',
  },

  // TITLE XXL
  titleXXL: {
    [slugs.homestead]: '!font-dharma text-[3rem] !font-[800] leading-[40px] tracking-[1.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[3rem] !font-[700] leading-[48px] tracking-[1.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[3rem] !font-[400] leading-[48px] tracking-[0.75px] !uppercase',
    [slugs.kingOfKings]: 'text-[3rem] !font-bold leading-[57.6px]',
    [slugs.theLastRodeo]: 'text-[3rem] !font-bold leading-[57.6px]',
  },

  // TITLE XL
  titleXL: {
    [slugs.homestead]: '!font-dharma text-[2.5rem] !font-[800] leading-[40px] tracking-[1.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[2.5rem] !font-[700] leading-[40px] tracking-[1.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[2.5rem] !font-[400] leading-[40px] tracking-[0.75px] !uppercase',
    [slugs.kingOfKings]: 'text-[2.5rem] !font-bold leading-[48px]',
    [slugs.theLastRodeo]: 'text-[2.5rem] !font-bold leading-[48px]',
  },

  // TITLE LG
  titleLG: {
    [slugs.homestead]: 'font-dharma text-[2rem] !font-[800] leading-[32px] tracking-[1.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[2rem] !font-[700] leading-[32px] tracking-[1.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[2rem] !font-[400] leading-[32px] tracking-[0.75px] !uppercase',
    [slugs.kingOfKings]: 'text-[2rem] !font-bold leading-[38.4px]',
    [slugs.theLastRodeo]: 'text-[2rem] !font-bold leading-[38.4px]',
  },

  // TITLE MD
  titleMD: {
    [slugs.homestead]: 'font-dharma text-[1.75rem] !font-[800] leading-[28px] tracking-[1.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.75rem] !font-[500] leading-[28px] tracking-[1.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.75rem] !font-[400] leading-[28px] tracking-[0.5px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.75rem] !font-bold leading-[33.6px]',
    [slugs.theLastRodeo]: 'text-[1.75rem] !font-bold leading-[33.6px]',
  },

  // EYEBROW XL
  eyebrowXL: {
    [slugs.homestead]: '', // not defined in original snippet
    [slugs.braveTheDark]: '!font-apotek text-[1.313rem] !font-[600] leading-[21px] tracking-[1px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.313rem] !font-[400] leading-[21px] tracking-[1px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.125rem] !font-semibold leading-[21px] tracking-[0.75px] !uppercase',
    [slugs.theLastRodeo]: 'text-[1.125rem] !font-semibold leading-[21px] tracking-[0.75px] !uppercase',
  },

  // EYEBROW LG
  eyebrowLG: {
    [slugs.homestead]: 'font-bio text-base !font-[600] leading-[16px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.125rem] !font-[600] leading-[18px] tracking-[1px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-base !font-[400] leading-[16px] tracking-[1px] !uppercase',
    [slugs.kingOfKings]: 'text-base !font-semibold leading-[19px] tracking-[0.75px] !uppercase',
    [slugs.theLastRodeo]: 'text-base !font-semibold leading-[19px] tracking-[0.75px] !uppercase',
  },

  // EYEBROW MD
  eyebrowMD: {
    [slugs.homestead]: 'font-bio text-sm !font-[600] leading-[14px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-base !font-[600] leading-[16px] tracking-[0.75px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-base !font-[400] leading-[16px] tracking-[0.75px] !uppercase',
    [slugs.kingOfKings]: 'text-[0.813rem] !font-semibold leading-[16px] tracking-[0.5px] !uppercase',
    [slugs.theLastRodeo]: 'text-[0.813rem] !font-semibold leading-[16px] tracking-[0.5px] !uppercase',
  },

  // EYEBROW SM
  eyebrowSM: {
    [slugs.homestead]: 'font-bio text-xs !font-[600] leading-[12px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[0.875rem] !font-[600] leading-[14px] tracking-[0.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[0.875rem] !font-[400] leading-[14px] tracking-[0.5px] !uppercase',
    [slugs.kingOfKings]: 'text-[0.688rem] !font-semibold leading-[13px] tracking-[0.5px] !uppercase',
    [slugs.theLastRodeo]: 'text-[0.688rem] !font-semibold leading-[13px] tracking-[0.5px] !uppercase',
  },

  // BUTTON MD
  btnMD: {
    [slugs.homestead]: 'font-bio text-[1.125rem] !font-[600] leading-[19.8px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.125rem] !font-[600] leading-[19px] tracking-[1px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.125rem] !font-[400] leading-[19px] tracking-[0.5px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.125rem] !font-medium leading-[22px] tracking-[0px]',
    [slugs.theLastRodeo]: 'text-[1.125rem] !font-medium leading-[22px] tracking-[0px]',
  },

  // BUTTON SM
  btnSM: {
    [slugs.homestead]: 'font-bio text-base !font-[600] leading-[19.2px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-base !font-[600] leading-[16px] tracking-[0.75px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-base !font-[400] leading-[16px] tracking-[0.5px] !uppercase',
    [slugs.kingOfKings]: 'text-base !font-medium leading-[20px] tracking-[0px]',
    [slugs.theLastRodeo]: 'text-base !font-medium leading-[20px] tracking-[0px]',
  },

  // BUTTON XS
  btnXS: {
    [slugs.homestead]: 'font-bio text-sm !font-[600] leading-[16.8px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-sm !font-[600] leading-[15px] tracking-[0.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-sm !font-[400] leading-[15px] tracking-[0.25px] !uppercase',
    [slugs.kingOfKings]: 'text-sm !font-medium leading-[18px] tracking-[0.5px]',
    [slugs.theLastRodeo]: 'text-sm !font-medium leading-[18px] tracking-[0.5px]',
  },

  // TICKET VALUE
  ticketValue: {
    [slugs.homestead]: 'font-bio text-[2.25rem] !font-[600] leading-[36px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[2.25rem] !font-[600] leading-[36px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[2.25rem] !font-[400] leading-[36px] !uppercase',
    [slugs.kingOfKings]: 'text-[2.25rem] !font-bold leading-[43.2px]',
    [slugs.theLastRodeo]: 'text-[2.25rem] !font-bold leading-[43.2px]',
  },

  // STEP VALUE XL
  stepValueXL: {
    [slugs.homestead]: 'font-bio text-[1.25rem] !font-[700] leading-[30px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.25rem] !font-[700] leading-[30px] tracking-[0.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.25rem] !font-[400] leading-[30px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.25rem] !font-bold leading-[24px]',
    [slugs.theLastRodeo]: 'text-[1.25rem] !font-bold leading-[24px]',
  },

  // STEP VALUE LG
  stepValueLG: {
    [slugs.homestead]: 'font-bio text-[1.125rem] !font-[700] leading-[27px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.125rem] !font-[700] leading-[27px] tracking-[0.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.125rem] !font-[400] leading-[27px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.125rem] !font-bold leading-[21.6px]',
    [slugs.theLastRodeo]: 'text-[1.125rem] !font-bold leading-[21.6px]',
  },

  // STEP VALUE MD
  stepValueMD: {
    [slugs.homestead]: 'font-bio text-[1rem] !font-[700] leading-[24px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1rem] !font-[700] leading-[24px] tracking-[0.5px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1rem] !font-[400] leading-[24px] !uppercase',
    [slugs.kingOfKings]: 'text-[1rem] !font-bold leading-[19.2px]',
    [slugs.theLastRodeo]: 'text-[1rem] !font-bold leading-[19.2px]',
  },

  // TAB TEXT
  tabText: {
    [slugs.homestead]: 'font-bio text-[1.125rem] !font-[600] leading-[18px] tracking-[0.5px] !uppercase',
    [slugs.braveTheDark]: '!font-apotek text-[1.25rem] !font-[600] leading-[18px] tracking-[1px] !uppercase',
    [slugs.ruleBreakers]: '!font-citrusGothic text-[1.25rem] !font-[400] leading-[18px] tracking-[0.5px] !uppercase',
    [slugs.kingOfKings]: 'text-[1.125rem] !font-bold leading-[24px]',
    [slugs.theLastRodeo]: 'text-[1.125rem] !font-bold leading-[24px]',
  },
}

interface Props extends TextProps {
  className?: string
  children: ReactNode
}

export const GDisplay: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()
  return (
    <Text {...textProps} className={classNames(textClasses.display[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTitleXXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.titleXXL[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTitleXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.titleXL[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTitleLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.titleLG[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTitleMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.titleMD[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTitleSM: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text {...textProps} className={classNames('!font-roboto text-[1.5rem] !font-[700] leading-[31px]', className)}>
      {children}
    </Text>
  )
}

export const GTitleXS: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text {...textProps} className={classNames('!font-roboto text-[1.25rem] !font-[700] leading-[26px]', className)}>
      {children}
    </Text>
  )
}

export const GEyebrowXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.eyebrowXL[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GEyebrowLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.eyebrowLG[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GEyebrowMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.eyebrowMD[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GEyebrowSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.eyebrowSM[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GParagraphLG: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('block !font-roboto !font-[400] text-[1.313rem] leading-[33px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GParagraphMD: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('block !font-roboto !font-[400] text-[1.125rem] leading-[28px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GParagraphSM: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('block !font-roboto !font-[400] text-base leading-[26px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GPromoTextSM: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text {...textProps} className={classNames('!font-roboto text-[1rem] !font-[400] leading-[21px]', className)}>
      {children}
    </Text>
  )
}

export const GPromoTextMD: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text {...textProps} className={classNames('!font-roboto text-[1.125rem] !font-[400] leading-[24px]', className)}>
      {children}
    </Text>
  )
}

export const GCaptionMD: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('!font-roboto text-base !font-[400] leading-[16px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GCaptionSM: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('!font-roboto !font-[400] text-[0.875rem] leading-[14px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GFooterMD: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('!font-roboto text-[0.75rem] !font-[400] leading-[18px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GFooterSM: FC<Props> = ({ children, className, ...textProps }) => {
  return (
    <Text
      {...textProps}
      className={classNames('!font-roboto text-[0.625rem] !font-[400] leading-[15px] tracking-[0px]', className)}
    >
      {children}
    </Text>
  )
}

export const GButtonMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.btnMD[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GButtonSM: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.btnSM[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GButtonXS: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.btnXS[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTicketValue: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.ticketValue[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GStepValueXL: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.stepValueXL[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GStepValueLG: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.stepValueLG[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GStepValueMD: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.stepValueMD[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

export const GTabText: FC<Props> = ({ children, className, ...textProps }) => {
  const { theatricalSlug } = useGiveawayTypographyContext()

  return (
    <Text {...textProps} className={classNames(textClasses.tabText[theatricalSlug], className)}>
      {children}
    </Text>
  )
}

interface ResponsiveTextProps extends TextProps {
  mobileClassName?: string
  desktopClassName?: string
  className?: string
  children: ReactNode
  MobileTextComponent: React.ComponentType<Props>
  DesktopTextComponent: React.ComponentType<Props>
}

export const GResponsiveText: FC<ResponsiveTextProps> = ({
  children,
  MobileTextComponent,
  DesktopTextComponent,
  mobileClassName,
  desktopClassName,
  className,
  ...textProps
}) => {
  return (
    <span>
      <MobileTextComponent className={classNames('md:hidden', className, mobileClassName)} {...textProps}>
        {children}
      </MobileTextComponent>

      <DesktopTextComponent className={classNames('hidden md:block', className, desktopClassName)} {...textProps}>
        {children}
      </DesktopTextComponent>
    </span>
  )
}

export const HomesteadTitleXL: FC<TextCompontent> = ({ className, children }) => (
  <h1
    className={classNames(
      'text-[40px] font-extrabold tracking-[1.2px] leading-none uppercase !font-[dharma-gothic-e]',
      className,
    )}
  >
    {children}
  </h1>
)

export const HomesteadTitleSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <TitleSM
    className={classNames('text-[21px] !font-[bio-sans] leading-[130%] font-[600]', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </TitleSM>
)

export const HomesteadEyebrowMD: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <EyebrowMD
    className={classNames('text-[14px] font-[500] !font-[bio-sans] uppercase tracking-[0.42px]', className)}
    color={color ? color : 'homestead-primary-blue'}
    weight={weight}
  >
    {children}
  </EyebrowMD>
)

export const HomesteadParagraphMD: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <ParagraphLG
    className={classNames('text-[18px] !font-[bio-sans] leading-[150%]', className)}
    color={color ? color : 'homestead-blue'}
    weight={weight}
  >
    {children}
  </ParagraphLG>
)

export const HomesteadParagraphSM: FC<TextCompontent> = ({ className, children, color, weight }) => (
  <ParagraphLG
    className={classNames('text-[16px] !font-[bio-sans] leading-[150%]', className)}
    color={color ? color : 'homestead-blue'}
    weight={weight}
  >
    {children}
  </ParagraphLG>
)

export const HomesteadButtonText: FC<{ className?: string; children: ReactNode }> = ({ className, children }) => (
  <Text className={classNames('!font-[bio-sans] text-[16px] font-semibold uppercase leading-[120%]', className)}>
    {children}
  </Text>
)
